import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { I18n } from "aws-amplify";
import { checkUserAuthentication, isInExtensionContext } from "src/utils/Utils";
import { isUserAuthenticated } from "src/helpers/fakebackend_helper";

const DocumentAnalysis = React.lazy(() => import('../DocumentAnalysis'));
const PagePricing = React.lazy(() => import('../Utility/PagePricing'));


const Demo = (props: any) => {

    let navigate = useNavigate();

    const [sampleDocumentMenu, setSampleDocumentMenu] = useState(false);
    const [prevSampleDocumentId, setPrevSampleDocumentId] = useState<string>("");
    const [sampleDocumentId, setSampleDocumentId] = useState<string>("sampleDocumentOne.pdf");
    const [sampleDocumentFileType, setSampleDocumentFileType] = useState<string>("pdf");

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        checkUserAuthentication().then((isAuthentcated) => {
            setIsAuthenticated(isAuthentcated);
        });
        setTimeout(() => {
            // change to pdf
            if (sampleDocumentId === "sampleDocumentOne.jpg") {
                setSampleDocumentId("sampleDocumentOne.pdf");
                setSampleDocumentFileType("pdf");
            }
        }, 5000);
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/Dashboard");
        }
    }, [isAuthenticated])

    const toggleSampleDocumentMenu = (e: any) => {
        console.log(e);
        setSampleDocumentMenu(!sampleDocumentMenu);
    };

    const onSetDocumentId = (documentId: string) => {
        if (prevSampleDocumentId === documentId) return;
        setSampleDocumentId(documentId);
    }

    const tryItYourselfDemo = <Row>
        {/* <h3 className="mb-3">Check out what BankStatementConverAI can do!</h3> */}
        <Col xl={12}>
            <Row>
                <Col xl={6}>
                    <Dropdown isOpen={sampleDocumentMenu} toggle={toggleSampleDocumentMenu} className="mb-3">
                        <DropdownToggle
                            className=""
                            to="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span className="text-white">Change sample document</span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={(e) => {
                                e.preventDefault();
                                onSetDocumentId("sampleDocumentOne.pdf");
                                setSampleDocumentFileType("pdf");
                            }}
                                className="dropdown-item">
                                sampleDocumentOne
                            </DropdownItem>
                            <DropdownItem href="#" onClick={(e) => {
                                e.preventDefault();
                                onSetDocumentId("sampleDocumentTwo.pdf");
                                setSampleDocumentFileType("pdf");
                            }} className="dropdown-item">
                                sampleDocumentTwo
                            </DropdownItem>
                            <DropdownItem href="#" onClick={(e) => {
                                e.preventDefault();
                                onSetDocumentId("sampleDocumentThree.pdf");
                                setSampleDocumentFileType("pdf");
                            }} className="dropdown-item">
                                sampleDocumentThree
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col xl={6}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            isInExtensionContext() && !isUserAuthenticated() ? window.open("/Dashboard", "_blank") :
                                navigate(`/Dashboard?isExtension=${isInExtensionContext()}`);
                        }}
                    >
                        <span>{I18n.get("Start analyzing my documents!")}</span>
                    </button>

                </Col>
            </Row>
            <DocumentAnalysis documentId={sampleDocumentId} isDemo={true} fileType={sampleDocumentFileType} />
        </Col>
    </Row>;

    if (props.embed) {
        return tryItYourselfDemo;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {tryItYourselfDemo}
                    <h3 className="mb-3">Pricing</h3>
                    <PagePricing />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Demo;
