import { Auth, I18n } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { isInExtensionContext } from "src/utils/Utils";
export interface StripePricingTableProps {
    lead: string;
    userId: string;
    email: string;
    pricingTableId?: string;
    publishableKey?: string;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const StripePricingTable = (props: any) => {

    const pricingTableIdTest = "prctbl_1NyUSwJAbGiTAcRw5aIiupq6";
    const publishableKeyTest = "pk_test_51NyU85JAbGiTAcRwBW9bGy2UkIqfqd45cdSYOkai1VHMbj2gyLLqjyQHiP6rwhH7NvEfAhVLPb2o9TQU3srRxL4500npo2cUFn";

    const pricingTableId = "prctbl_1NyUNeJAbGiTAcRwLM04InyL";
    const publishableKey = "pk_live_51NyU85JAbGiTAcRwOlXeyMzoyrxTcMAk0N9KLxBngGKvQF6KcBr7FIecYHgdBMJdvNLDrpWntlVE7U8dIG1Me8js00UpzBquV8";

    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    useEffect(() => {

        // if loaded in a chrome extension, open a new tab with the stripe pricing table
        // uses query params to determine if the page is loaded in an extension
        if (isInExtensionContext()) {
            // reopen the current page in a new tab. Without query params
            const baseUrl = window.location.href.split("?")[0];
            window.open(`${baseUrl}`, "_blank");
        }
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);

    return (
        <div>
            <h2 className="text-center">
                {I18n.get(props.lead)}
                <br />
                <button className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                        window.open("/Dashboard", "_blank");
                    }}>
                    Click here if you are not redirected!
                </button>
            </h2>
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={userId}
                customer-email={email}>
            </stripe-pricing-table>
        </div >
    );
};
export default withTranslation()(StripePricingTable);
