import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import DocumentAnalysis from "../pages/DocumentAnalysis";
import ThankYou from "src/pages/Subscriptions/thank_you";
import Landing from "src/pages/Landing";
import Paywall from "src/pages/Subscriptions/Paywall";
import PagePricing from "src/pages/Utility/PagePricing";
import PageFaqs from "src/pages/Utility/PageFAQs";
import PageStarter from "src/pages/Utility/PageStarter";
import TOS from "src/pages/Legal/TOS";
import PrivacyPolicy from "src/pages/Legal/PrivacyPolicy";
import Demo from "src/pages/Demo";
import ArticleDetails from "src/pages/Blog/articleDetails";
import Blog from "src/pages/Blog";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const authRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/thank-you", component: <ThankYou /> },

  // Document analysis
  { path: "/document/:documentId/analyze/:fileType", component: <DocumentAnalysis /> },

  { path: "/document/:documentId/analyze/:fileType/", component: <DocumentAnalysis /> },

  { path: "/pricing", exact: true, component: <Paywall isSubscribed={false} /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const nonAuthRoutes: Array<RouteProps> = [
  { path: "/pricing-non-auth", exact: true, component: <PagePricing /> },

  { path: "/demo", component: <Demo /> },

  { path: "/blog", component: <Blog /> },

  { path: "/articles/:articleId/:articleTitle", component: <ArticleDetails /> },

  { path: "/faq", exact: true, component: <PageFaqs /> },

  { path: "/privacy-policy", exact: true, component: <PrivacyPolicy /> },

  { path: "/tos", exact: true, component: <TOS /> },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Landing /> },
];

export { authRoutes, nonAuthRoutes };
