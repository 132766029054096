import { Auth, Storage } from "aws-amplify";
const { v4: uuidv4 } = require('uuid');

const DOCUMENTS_SUB_FOLDER = "documents";

export enum AccessLevel {
    Public = "public",
    Protected = "protected",
    Private = "private",
}

export interface DocumentMetadata {
    key: string,
    lastModified: Date,
    eTag?: string
}

export interface UserDocument {
    src: string;
    key: string;
    lastModified: Date;
}

// Documents
export const deleteDocumentFolder = async (id: string): Promise<boolean> => {
    if (!id) return false;
    let deleteReq: any[] = [];
    Storage.list(`${DOCUMENTS_SUB_FOLDER}`, { level: AccessLevel.Private })
        .then(({ results }) => {
            results.forEach((result) => {
                deleteReq.push(Storage.remove(result.key || "", { level: AccessLevel.Private }));
            })
        })
        .catch((err) => console.error(err));
    Promise.all(deleteReq)
    return true
}

export const getDocuments = async (): Promise<UserDocument[]> => {
    try {
        const response = await Storage.list(`${DOCUMENTS_SUB_FOLDER}/`, {
            level: AccessLevel.Private,
            pageSize: 'ALL',
        });

        if (!response.results) {
            console.error('No results from Storage.list');
            return [];
        }

        const metadata: DocumentMetadata[] = response.results
            .filter(resp => !!resp.key)
            .map(resp => ({
                key: resp.key || "",
                eTag: resp.eTag || "",
                lastModified: resp.lastModified || new Date(),
            }));

        const resultPromises = metadata.map(meta =>
            Storage.get(meta.key, { level: 'private' })
        );

        const documentUrls = await Promise.all(resultPromises);

        const documents: UserDocument[] = documentUrls.map((url, i) => ({
            src: url,
            key: metadata[i].key,
            lastModified: metadata[i].lastModified,
        }));

        return documents.sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime());

    } catch (error) {
        console.error('Error fetching documents:', error);
        return [];
    }
}

export const getDocument = async (key: string): Promise<UserDocument | null> => {
    try {
        const url = await Storage.get(key, { level: AccessLevel.Private });
        const document: UserDocument = {
            src: url,
            key: key,
            lastModified: new Date(),  // Modify this if you have a way to get the last modified date
        };
        return document;
    } catch (error) {
        console.error('Error fetching document:', error);
        return null;
    }
};


// Image operations
export const uploadDocument = async (document: File, documentFileName: string): Promise<UserDocument> => {
    // Validate inputs
    if (!document || !documentFileName) {
        throw new Error('Both document and documentFileName are required.');
    }
    const documentId = uuidv4();

    // Sanitize the user's file name to ensure a valid file name for S3
    const sanitizedFileName = documentFileName.replace(/\s+/g, '_').replace(/[^\w.-]/g, '');
    // Construct the file name for S3
    const fileName = `${documentId}--_${sanitizedFileName}`;
    try {
        // Upload the document to S3
        const response = await Storage.put(
            `${DOCUMENTS_SUB_FOLDER}/${fileName}`,
            document,
            {
                level: AccessLevel.Private,
            }
        );

        // Check for unexpected response values
        if (!response || !response.key) {
            throw new Error('Unexpected response from Storage.put');
        }
        // Retrieve the document URL from S3
        const src = await Storage.get(response.key, { level: AccessLevel.Private });

        return {
            src,
            key: response.key,
            lastModified: new Date(),
        };
    } catch (error) {
        console.error('Error uploading document:', error);
        throw error;  // Re-throw the error so it can be handled by the calling code
    }
}


export const removeDocument = async (document?: UserDocument): Promise<boolean> => {
    if (!document) {
        console.error('No document provided for removal.');
        return false;
    }

    try {
        await Storage.remove(document.key, { level: AccessLevel.Private });
        return true;
    } catch (error) {
        console.error(`Error removing document ${document.key}:`, error);
        return false;
    }
}


