import { useEffect, useRef, useState } from "react";
import { Table, Form, Input } from 'reactstrap';

function toTableArray(tableData: any) {
    const tableArray = [];
    for (let row of tableData.table) {
        const rowData = row.map((cell: any) => cell.text);
        tableArray.push(rowData);
    }
    return tableArray;
}

function isNullOrEmpty(str?: string): boolean {
    return !str || str.length === 0 || str === "";
}

const CSVTable: React.FC<{ tableArray: string[][] }> = ({ tableArray }) => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>('');
    const [loadedRows, setLoadedRows] = useState<number>(8);
    const tableContainerRef = useRef<any>(null);

    const itemsPerPage = 8;
    const totalItems = tableArray.length;
    const endIndex = Math.max(loadedRows, itemsPerPage);

    const currentTableArray = tableArray.slice(1, endIndex + 1);
    const filteredTableArray = currentTableArray.filter((row) => {
        return row.some(cell => !isNullOrEmpty(searchTerm) ? cell.toLowerCase().includes(searchTerm!.toLowerCase()) : cell)
    });

    const handleScroll = (e: any) => {
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            setLoadedRows(prevRows => Math.min(prevRows + itemsPerPage, totalItems));
        }
    };

    return (
        <div>
            {totalItems <= 0 && <p> No table detected</p>}
            {totalItems > 0 &&
                <>
                    <Form className="mb-3">
                        <Input
                            type="text"
                            placeholder="Search your data..."
                            value={searchTerm || ""}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form>
                    <div
                        ref={tableContainerRef}
                        onScroll={handleScroll}
                        style={{ maxHeight: '400px', overflowY: 'scroll' }} // Adjust the height as necessary
                    >
                        <Table hover striped bordered>
                            <thead className=''>
                                <tr>
                                    {tableArray[0].map((cell, cellIndex) => (
                                        <th key={`header_cell_${cellIndex}`}>{cell}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTableArray.map((row, rowIndex) => (
                                    <tr key={`row_${rowIndex + 1}`}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={`cell_${cellIndex}`}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </>
            }
        </div>
    );
}

interface RenderTableProps {
    tableData: {
        size: {
            rows: number;
            columns: number;
        };
        table: {
            text: string;
            boundingBox: {
                height: number;
                left: number;
                top: number;
                width: number;
            };
            polygon: {
                x: number;
                y: number;
            }[];
            rowSpan: number;
            columnSpan: number;
        }[][];
    };
}

const RenderTable: React.FC<RenderTableProps> = ({ tableData }) => {
    const tableArray = toTableArray(tableData);
    return (
        <div>
            {tableArray && <CSVTable tableArray={tableArray} />}
            {!tableArray || tableArray.length === 0 && <p>No table detected</p>}
        </div>
    );
}

export default RenderTable;
