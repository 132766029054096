import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "../../../i18n";
import { I18n } from "aws-amplify";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState<string>("");
  const [menu, setMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    I18n.setLanguage(lang);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>

    </>
  );
};

export default withTranslation()(LanguageDropdown);
