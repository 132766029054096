import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";
import { Auth, I18n } from "aws-amplify";
import { useNavigate } from "react-router";
import { isInExtensionContext } from "src/utils/Utils";
import { isUserAuthenticated } from "src/helpers/fakebackend_helper";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [userId, setUserId] = useState("")

  let navigate = useNavigate();

  useEffect(() => {
    Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
      if (userInfo && userInfo.attributes) {
        setUserId(userInfo.attributes.sub);
        setName(userInfo.attributes.name);
      }
    });
  }, []);

  return (
    <React.Fragment>
      {!userId &&
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => {
            isInExtensionContext() && !isUserAuthenticated() ? window.open("/Dashboard", "_blank") :
              navigate(`/Dashboard?isExtension=${isInExtensionContext()}`);
          }}
        >
          <span>{I18n.get("Get started for free!")}</span>
        </button>
      }
      {userId && <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="">{name || `Account`}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <a className="dropdown-item hover-pointer" onClick={async () => {
            Auth.signOut();
          }}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{I18n.get("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
      }
    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
