import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { urlSafeBase64Decode } from "src/utils/Utils";
import RenderDocumentAnalysis from "./RenderDocumentAnalysis";



export interface DocumentAnalysisProps {
    documentId?: string;
    isDemo?: boolean;
    fileType?: string;
}

const DocumentAnalysis = (props: DocumentAnalysisProps) => {
    let { documentId } = useParams<{ documentId: string }>();
    let { fileType } = useParams<{ fileType: string }>();

    documentId = documentId || props.documentId;
    documentId = urlSafeBase64Decode(documentId || "");
    fileType = fileType || props.fileType || "";

    let [searchParams, _] = useSearchParams();

    let isDemo: boolean = searchParams.get("isDemo") === "true" || props.isDemo === true;

    function renderDocumentAnalysis() {
        return (
            <RenderDocumentAnalysis
                documentId={documentId || ""}
                fileType={fileType || ""}
                isDemo={isDemo} />
        );
    }

    return (
        <>
            {isDemo && renderDocumentAnalysis()}
            {!isDemo && <React.Fragment>
                <div className="page-content">
                    {renderDocumentAnalysis()}
                </div >
            </React.Fragment >}
        </>
    );
}
export default DocumentAnalysis;

