import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Bloglist Data
import { blogArticleList } from 'src/common/data/blog';
import { useParams } from 'react-router-dom';

const ArticleDetails = (props: any) => {

  let { articleId } = useParams<{ articleId: string }>();
  articleId = articleId || props.articleId;

  const article = blogArticleList.find((article) => article.id === articleId) || blogArticleList[0]
  document.title = article.title;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="text-center mb-3">
                    <h2>{article.title}</h2>
                  </div>
                  <div className="mb-4">
                    <img
                      src={article.img}
                      alt=""
                      className="img-thumbnail mx-auto d-block small-image"
                    />
                  </div>


                  <div className="mt-4">
                    <div className="text-muted font-size-16">
                      <div dangerouslySetInnerHTML={{ __html: article.content }} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ArticleDetails
