import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DocumentList from "./DocumentList";
import { UserDocument, getDocuments } from "src/helpers/aws/aws_s3_helper";
import DocumentUploader from "./DocumentUploader";
import { useNavigate } from "react-router";
import { extractFileExtension, getFileType, urlSafeBase64Encode } from "src/utils/Utils";

interface DocumentManagerProps {
    setDocumentCount: (count: number) => void;  // Add setDocumentsUploadedCount prop
}

const DocumentManager: React.FC<DocumentManagerProps> = ({ setDocumentCount }) => {
    const navigate = useNavigate();
    const [documents, setDocuments] = useState<UserDocument[]>([]);

    const fetchDocuments = async () => {
        const docs = await getDocuments();
        setDocuments(docs);
        setDocumentCount(docs.length);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    function handleUpload(documentId: string) {
        if (documentId) {
            const encodedDocumentId = urlSafeBase64Encode(documentId);
            navigate(`/document/${encodedDocumentId}/analyze/${extractFileExtension(documentId)}`)
        } else {
            fetchDocuments();
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={4}>
                    <DocumentUploader onUpload={handleUpload} />
                </Col>
                <Col xl={8}>
                    <DocumentList
                        documents={documents}
                        onRefresh={fetchDocuments} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DocumentManager;
