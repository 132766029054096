import { useEffect, useState } from "react";
import { Card, CardBody, Pagination, PaginationItem, PaginationLink, Spinner } from "reactstrap";
import { UserDocument, removeDocument } from "src/helpers/aws/aws_s3_helper";
import { extractFilename, getFileType, urlSafeBase64Encode } from "src/utils/Utils";

interface DocumentListProps {
    documents: UserDocument[];
    onRefresh: () => Promise<void>;  // Add onRefresh prop
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onRefresh }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);  // Step 2: Keep track of the current page
    const itemsPerPage = 10;  // Step 1: Determine the number of items per page
    const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);

    useEffect(() => {
        setIsLoadingDocuments(false);
    }, [documents]);

    const handleDelete = async (document: UserDocument) => {
        const result = await removeDocument(document);
        if (result) {
            onRefresh();  // Call onRefresh after a successful deletion
        } else {
            console.error('Failed to delete document', document.key);
        }
    };

    const filteredDocuments = documents.filter(document =>
        document.key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalItems = filteredDocuments.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentDocuments = filteredDocuments.slice(startIndex, endIndex);  // Step 3: Calculate the range of items

    return (
        <Card className="card-h-100">
            <CardBody>
                <div className="d-flex flex-wrap align-items-center mb-4 p-0">
                    <h5 className="card-title me-2">My Documents</h5>
                </div>
                <p>Please note, the retention policy for your documents is 24 hours.</p>
                <form className="app-search d-none d-lg-block" style={{ width: '30vw' }}>
                    <div className="position-relative">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by document name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </form>
                <div className="table-responsive">
                    <table
                        role="table"
                        className="table align-middle datatable dt-responsive table-check nowrap table table-hover"
                    >
                        <thead>
                            <tr role="row">
                                <th>
                                    <div
                                        className="mb-2"
                                        title="Toggle SortBy"
                                        style={{ cursor: "pointer" }}
                                    >
                                        Name
                                    </div>
                                    <div style={{ marginTop: 5 }} />
                                </th>
                                <th>
                                    <div
                                        className="mb-2"
                                        title="Toggle SortBy"
                                        style={{ cursor: "pointer" }}
                                    >
                                        Type
                                    </div>
                                    <div style={{ marginTop: 5 }} />
                                </th>
                                <th>
                                    <div className="mb-2">Action</div>
                                    <div style={{ marginTop: 5 }} />
                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            {isLoadingDocuments &&
                                <div className="text-center mt-3">
                                    <Spinner color="primary" />
                                    <div>Loading...</div>
                                </div>
                            }
                            {!isLoadingDocuments && currentDocuments.map((document, index) => {
                                const encodedDocumentName = urlSafeBase64Encode(document.key);
                                return (
                                    <tr key={index}>
                                        <td role="cell">
                                            <h5 className="font-size-14 mb-1">
                                                <a className="text-dark" href={`/document/${encodedDocumentName}/analyze/${getFileType(document)}`}>
                                                    {extractFilename(document.key, true)}
                                                </a>
                                            </h5>
                                            <p className="text-muted mb-0">Uploaded on: {new Date(document.lastModified).toLocaleDateString()}</p>
                                        </td>
                                        <td role="cell">
                                            <h5 className="font-size-14 mb-1">
                                                {/* Assuming file type can be extracted from the document name */}
                                                {document && document.key &&
                                                    <a className="text-dark" href={`/document/${encodedDocumentName}/analyze/${getFileType(document)}`}>
                                                        {document.key.split('.').pop()?.toUpperCase()}
                                                    </a>
                                                }
                                            </h5>
                                            {/* Assuming pages information is not available */}
                                            {/* <p className="text-muted mb-0">5 pages</p> */}
                                        </td>
                                        <td role="cell">
                                            <div className="d-flex gap-3">
                                                <a className="text-success" href={`/document/${encodedDocumentName}/analyze/${getFileType(document)}`}>
                                                    <i className="mdi mdi-eye font-size-16" id="edittooltip"></i>
                                                    <span className="m-2">Analyze</span>
                                                </a>
                                                <a className="text-danger" href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDelete(document);
                                                }}>
                                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                                                    <span className="m-2">Delete</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination aria-label="Table navigation" className="mt-4">
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink previous href="#" onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))} />
                    </PaginationItem>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <PaginationItem active={index + 1 === currentPage} key={index}>
                            <PaginationLink href="#" onClick={() => setCurrentPage(index + 1)}>
                                {index + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink next href="#" onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))} />
                    </PaginationItem>
                </Pagination>
            </CardBody>
        </Card>
    );
}

export default DocumentList;
