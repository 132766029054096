import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

// import common data
import { DashboardProps } from "../../common/data/dashboard";
import DocumentManager from "../DocumentManager";
import { verifyUsage } from "src/helpers/usage/usage_helper";
import { NotEnougCreditsError, SubscriptionRequiredError } from "src/common/ApiResponseErrors";
import Paywall from "../Subscriptions/Paywall";

const Dashboard = () => {
  document.title = "BankStatementConverterAI | The best bank statement to excel converter in the world!";

  const [documentsUploadedCount, setDocumentsUploadedCount] = useState<DashboardProps>({
    id: 1,
    title: "Documents Uploaded",
    price: 0,
    rank: `${0} Documents`,
    isDoller: false,
    postFix: "",
    statusColor: "primary",
    series: []
  });
  const [pagesPerMonthAllowed, setPagesPerMonthAllowed] = useState<DashboardProps>({
    id: 2,
    title: "Your Subscription Allows ",
    price: 0,
    rank: `${0} Pages per Month`,
    isDoller: false,
    postFix: "",
    statusColor: "info",
    series: []
  });
  const [pagesProcessedCount, setPagesProcessedCount] = useState<DashboardProps>({
    id: 3,
    title: "Pages Processed this Month",
    price: 0,
    rank: `${0} Pages Processed this Month`,
    isDoller: false,
    postFix: "",
    statusColor: "warning",
    series: []
  });

  const [pagesRemainingCount, setPagesRemainingCount] = useState<DashboardProps>({
    id: 4,
    title: "Pages Remaining this Month",
    price: 0,
    rank: `${0} Pages Remaining this Month`,
    isDoller: false,
    postFix: "",
    statusColor: "success",
    series: []
  });

  const [widgets, setWidgets] = useState<DashboardProps[]>();

  const [isSubscriptionStatusChecked, setIsSubscriptionStatusChecked] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);


  useEffect(() => {
    setWidgets([
      documentsUploadedCount,
      pagesProcessedCount,
      pagesPerMonthAllowed,
      pagesRemainingCount]
    );
  }, [documentsUploadedCount, pagesPerMonthAllowed, pagesPerMonthAllowed, pagesRemainingCount]);

  useEffect(() => {
    setLoading(true);

    verifyUsage().then((response) => {
      setIsSubscriptionStatusChecked(true);
      setIsSubscribed(true);
      setLoading(false);

      let creditsRemaining = response.body.usage.creditsRemaining || 0;
      console.log(creditsRemaining);
      setPagesRemainingCount({
        ...pagesRemainingCount,
        rank: `${creditsRemaining + 1} Pages Remaining this Month`,
      });

      let creditsPerMonth = response.body.usage.creditsPerMonth;
      let rank = `${creditsPerMonth} Pages per Month`;
      if (creditsPerMonth === 0) {
        rank = "None. Please subscribe. Thank you!"
      }
      setPagesPerMonthAllowed({
        ...pagesPerMonthAllowed,
        rank
      });
      if (creditsPerMonth === 0) creditsPerMonth = response.body.usage.maxFreeCredits; // For new accounts
      setPagesProcessedCount({
        ...pagesProcessedCount,
        rank: `${creditsPerMonth - (creditsRemaining + 1)} Page(s) Processed this Month`,
      });
    }).catch(error => {
      setIsSubscriptionStatusChecked(true);
      setIsSubscribed(false);
      setLoading(false);

      if (error instanceof SubscriptionRequiredError) {
      } else if (error instanceof NotEnougCreditsError) {
      }
      console.error(error);
    });
  }, []);

  function setDocumentCount(count: number) {
    setDocumentsUploadedCount({
      id: count,
      title: "Docs Uploaded",
      price: 0,
      rank: `${count} Document(s)`,
      isDoller: false,
      postFix: "",
      statusColor: "primary",
      series: []
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Bank Statement Converter AI" breadcrumbItem="Bank Statement Converter AI" />
          {loading &&
            <div className="text-center mt-3">
              <Spinner color="primary" />
              <div>Loading...</div>
            </div>
          }
          {isSubscriptionStatusChecked && !loading && !isSubscribed &&
            <Paywall isSubscribed={isSubscribed} embedded={true} />
          }
          {isSubscriptionStatusChecked && !loading && isSubscribed &&
            <>
              <Row>
                {(widgets || []).map((widget, key) => (
                  (
                    <Col xl={3} md={6} key={key}>
                      <Card className="card-h-100">
                        <CardBody>
                          <Row className="align-items-center">
                            <Col xs={8}>
                              <span className="text-muted mb-3 lh-1 d-block">
                                {widget.title}
                              </span>
                              <h4 className="mb-3">
                                {widget.isDoller === true ? "$" : ""}
                                <span className="counter-value">
                                  {widget.postFix}
                                </span>
                              </h4>
                            </Col>
                            <Col xs={6}>
                            </Col>
                          </Row>
                          <div className="text-nowrap">
                            <span
                              className={
                                "badge bg-" +
                                widget.statusColor +
                                "-subtle text-" +
                                widget.statusColor
                              }
                            >
                              {widget.rank}
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )))}
              </Row>
              <Row>
                <DocumentManager setDocumentCount={setDocumentCount} />
              </Row></>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
