import { saveAs } from 'file-saver';
import { fromBase64, toBase64 } from '@aws-sdk/util-base64-browser';
import { Auth } from 'aws-amplify';
import { UserDocument } from 'src/helpers/aws/aws_s3_helper';

let xlsxUtils: any;
let xlsxWriteFile: any;

let pdfjsLib: any;

async function loadingPdfJs() {
    try {
        const module = await import('jspdf');
        pdfjsLib = module.default || module;
    } catch (error) {
        console.error('Error loading the pdfjs module', error);
    }
}


async function loadXlsx() {
    try {
        const xlsx = await import('xlsx');
        xlsxUtils = xlsx.utils;
        xlsxWriteFile = xlsx.writeFile;
        // xlsxUtils and xlsxWriteFile are now ready to use
    } catch (error) {
        console.error('Error loading the xlsx module', error);
    }
}

async function getXlsxUtils(): Promise<any> {
    if (!xlsxUtils) {
        await loadXlsx();
    }
    return xlsxUtils;
}

async function getXlsxWriteFile(): Promise<any> {
    if (!xlsxWriteFile) {
        await loadXlsx();
    }
    return xlsxWriteFile;
}

async function getPdfJs(): Promise<any> {
    if (!pdfjsLib) {
        await loadingPdfJs();
    }
    return pdfjsLib;
}

export function makeCamelCase(obj: object | undefined, keys?: string[]) {
    if (!obj) return undefined;
    const newObj = {};
    const keysToRename = keys ? keys : Object.keys(obj);
    keysToRename.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            // change the key to camelcase.
            const camelCaseKey = key.charAt(0).toLowerCase() + key.substr(1);
            // @ts-ignore
            Object.assign(newObj, { [camelCaseKey]: obj[key] });
        }
    });
    return newObj;
}

/**
 * Given an array of object, call makeCamelCase(...) on each option.
 */
export function makeCamelCaseArray(objArr: object[] | undefined, keys?: string[]) {
    if (!objArr) return undefined;
    return objArr.map(obj => makeCamelCase(obj, keys));
}

/**
 * Converts blob to array buffer
 */
export function blobToArrayBuffer(blob: Blob): Promise<Uint8Array> {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = _event => {
            res(reader.result as Uint8Array);
        };
        reader.onerror = err => {
            rej(err);
        };
        try {
            reader.readAsArrayBuffer(blob);
        } catch (err) {
            rej(err); // in case user gives invalid type
        }
    });
}

function toCSV(tableData: any) {
    const csvRows = [];
    for (let row of tableData.table) {
        const rowData = row.map((cell: any) => `"${cell.text.replace(/"/g, '""')}"`).join(',');
        csvRows.push(rowData);
    }
    const csvString = csvRows.join('\r\n');
    return new Blob([csvString], { type: 'text/csv' });
}

async function toExcel(tableData: any) {
    const utils = await getXlsxUtils();
    const ws = utils.aoa_to_sheet(tableData.table.map((row: any) => row.map((cell: any) => cell.text)));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    return wb;
}

export function downloadCSV(tableData: any, fileName: string,) {
    saveAs(toCSV(tableData), `bankstatementconverterai.com_${fileName}.csv`);
}

export async function downloadExcel(tableData: any, fileName: string) {
    const wb = await toExcel(tableData);  // Await the result of toExcel
    const writeFile = await getXlsxWriteFile();  // Await the result of getXlsxWriteFile
    writeFile(wb, `bankstatementconverterai.com_${fileName}.xlsx`);  // Use the writeFile function
}

export async function checkUserAuthentication(): Promise<boolean> {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Encodes a string into URL-safe base64 format.
 * @param input The string to encode.
 * @returns The URL-safe base64 encoded string.
 */
export function urlSafeBase64Encode(input: string): string {
    const utf8Bytes = new TextEncoder().encode(input);
    return toBase64(utf8Bytes);
}

/**
 * Decodes a URL-safe base64 encoded string.
 * @param input The URL-safe base64 encoded string.
 * @returns The decoded string.
 */
export function urlSafeBase64Decode(input: string): string {
    try {
        const decodedBytes = fromBase64(input);
        return new TextDecoder().decode(decodedBytes);
    } catch (error) {
        console.error(error);
    }
    return input;
}

export function getFileType(document: UserDocument) {
    return extractFileExtension(document.key);
}

export function extractFileExtension(src: string) {
    let fileType = "image";
    if (src) {
        fileType = src.split('.').pop()?.toLocaleLowerCase() || fileType;
    }
    return fileType;
}

export function extractFilename(key: string, spaceOutName: boolean) {
    if (!key || typeof key !== 'string') {
        return '';  // Return an empty string for null, undefined, or non-string inputs
    }

    const delimiter = '--_';

    // Find the position of the delimiter and extract everything after it
    const startPos = key.indexOf(delimiter);
    if (startPos === -1) {
        return key;  // Return an empty string if the delimiter is not found
    }

    const filenameWithExt = key.substring(startPos + delimiter.length);

    // Replace underscores with spaces
    const filename = spaceOutName ? filenameWithExt.replace(/_/g, ' ') : filenameWithExt;

    // Split by dot and exclude the extension
    const nameParts = filename.split('.');
    if (nameParts.length <= 1) {
        return filename;  // Return the filename as is if there's no dot
    }

    nameParts.pop();  // Remove the last part (extension)
    return nameParts.join('.');
}

export const convertToPDF = (file: File): Promise<File> => {
    const ext = extractFileExtension(file.name);
    if (ext === "PDF" || ext === "pdf" || !ext || ext === "") {
        return Promise.resolve(file);
    }

    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = function () {
                if (typeof reader.result === "string") {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = async () => {
                        // Calculate aspect ratio to retain the image's proportions
                        const aspectRatio = img.width / img.height;
                        let widthInMM = 180; // default width
                        let heightInMM = widthInMM / aspectRatio;

                        // Check if height exceeds A4 height (297mm) minus margins
                        if (heightInMM > 277) { // Assuming 10mm margins
                            heightInMM = 277;
                            widthInMM = heightInMM * aspectRatio;
                        }
                        await getPdfJs();
                        const pdf = new pdfjsLib('p', 'mm', 'a4');
                        pdf.addImage(reader.result as string, 'PNG', 10, 10, widthInMM, heightInMM);

                        const blob = pdf.output('blob');
                        const pdfFile = new File([blob], `${file.name}.pdf`, { type: 'application/pdf' });
                        resolve(pdfFile);
                    };
                } else {
                    reject(new Error('FileReader result is not a string.'));
                }
            };
            reader.onerror = () => {
                reject(new Error('Error reading the file.'));
            };
            reader.readAsDataURL(file);
        } else {
            reject(new Error('File is not provided.'));
        }
    });
}

export function isInExtensionContext(): boolean {
    // check if current url contains isExtension=true
    const urlParams = new URLSearchParams(window.location.search);
    const isExtension = urlParams.get("isExtension");
    if (isExtension === "true") {
        return true;
    }
    return false;
}
