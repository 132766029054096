
import { getDocument } from "src/helpers/aws/aws_s3_helper";

export async function fetchDocument(documentId: string, setDocumentData: Function) {
    try {
        const decodedDocumentId = decodeURIComponent(documentId || "");
        const doc = await getDocument(decodedDocumentId || "");
        setDocumentData(doc);
    } catch (error) {
        console.error("Failed to fetch document:", error);
    }
}

export function pdfPageToImage(page: any, scale = 1) {
    const viewport = page.getViewport({ scale });
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    return page.render({ canvasContext, viewport }).promise.then(() => {
        return { url: canvas.toDataURL(), width: viewport.width, height: viewport.height }
    });
}

function convertToPixels(boundingBox: any, currentWidth: number, currentHeight: number,
    initialWidth: number, initialHeight: number) {
    const widthScale = currentWidth / initialWidth;
    const heightScale = currentHeight / initialHeight;

    return {
        left: boundingBox.left * initialWidth * widthScale,
        top: boundingBox.top * initialHeight * heightScale,
        width: boundingBox.width * initialWidth * widthScale,
        height: boundingBox.height * initialHeight * heightScale,
    };
}

export function getOverlayStyle(boundingBox: any, currentWidth: number, currentHeight: number, initialWidth: number,
    initialHeight: number, isActive: boolean, zoom: number): {} {
    const pixelBox = convertToPixels(boundingBox, currentWidth, currentHeight, initialWidth, initialHeight);
    return {
        backgroundColor: isActive ? 'rgba(255, 0, 0, 0.2)' : undefined,
        position: 'absolute',
        left: `${pixelBox.left}px`,
        top: `${pixelBox.top}px`,
        width: `${pixelBox.width}px`,
        height: `${pixelBox.height}px`,
        borderColor: 'red',
        borderWidth: '2px',
        borderStyle: 'solid',
        boxSizing: 'border-box',
    };
}
