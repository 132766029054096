import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import BlogList Data
import {
  blogArticleList,
} from "../../common/data/blog";
import { Link } from "react-router-dom";

const Blog = (props: any) => {

  if (!props.isDemo) {
    document.title = "Blog | Convert bank statements PDF to Excel or CSV.";
  }

  const truncateText = (text: string, length: number) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };

  const renderBlog = () => {
    return (
      <>
        <Row className="row align-items-center mt-0">
          <Col md={6}>
            <div className="mb-3">
              <h5 className="card-title">
                Bank Statement Converter AI Blog{" "}
                <span className="text-muted fw-normal ms-2">({blogArticleList.length})</span>
              </h5>
            </div>
          </Col>
        </Row>
        {/* <!-- end row --> */}
        <Row className="">
          {[...blogArticleList].reverse().map((item, key) => (
            <Col xl={4} sm={6} key={key}>
              <Card>
                <div className="image-container mt-2">
                  <img src={item.img} alt="" style={{ height: '35vh' }} className="img-fluid" />
                </div>
                <CardBody>
                  <p className="text-muted mb-2">{item.date}</p>
                  <h5 className="text-body" style={{ height: '10vh' }}>
                    <Link className="text-dark" to={`/articles/${item.id}/${item.title.replace(/ /g, "-")}`}>
                      {item.title}
                    </Link>
                  </h5>
                  <p className="mb-0 font-size-15">{truncateText(item.desc, 100)}</p>
                  <div className="mt-3">
                    <Link className="align-middle font-size-15" to={`/articles/${item.id}/${item.title.replace(/ /g, "-")}`}>
                      Read more about: {item.title}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  }

  <>
    {props.isDemo && renderBlog()}
    {!props.isDemo && <React.Fragment>
      <div className="page-content">
        {renderBlog()}
      </div >
    </React.Fragment >}
  </>

  return (
    <>
      {props.isDemo && renderBlog()}
      {!props.isDemo && <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Blog" breadcrumbItem="Blog List" />
            {renderBlog()}
          </Container>
        </div>
      </React.Fragment>
      }
    </>
  );
};

export default Blog;
