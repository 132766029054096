import { I18n } from "aws-amplify";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const ThankYou = (props: any) => {
    return (
        <>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                <h1 className="display-1 fw-semibold">
                                    <span className="text-primary mx-2">{I18n.get("thank_you")}</span> {I18n.get("for_subs")}
                                </h1>
                                <h4 className="text-uppercase">{I18n.get("thank_you_start_converting_your_documents")}</h4>
                                <div className="mt-5 text-center">
                                    <Link to={"/dashboard"}>
                                        Start your document analysis by uploading here!!
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ThankYou;
