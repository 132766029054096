
export const Paginator = ({ pageNumber, pageCount, onPageChange, isZeroBased, previousLabelText, nextLabelText }:
    {
        pageNumber: number, pageCount: number, onPageChange: (pageNum: number) => void, isZeroBased: boolean,
        previousLabelText: string, nextLabelText: string
    }) => {

    let isAtStart: boolean = isZeroBased ? pageNumber <= 0 : pageNumber <= 1;
    let isAtEnd: boolean = isZeroBased ? pageNumber + 1 >= pageCount : pageNumber >= pageCount;

    return (
        <div className="d-flex gap-3">
            <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                disabled={isAtStart}
                onClick={() => onPageChange(pageNumber - 1)}
            >
                {previousLabelText}
            </button>
            <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                disabled={isAtEnd}
                onClick={() => onPageChange(pageNumber + 1)}
            >
                {nextLabelText}
            </button>
        </div>
    );
};
