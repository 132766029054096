import { Auth } from "aws-amplify";
import { BlockList } from '@aws-amplify/predictions/lib/types/AWSTypes';
import { categorizeTextractBlocks } from "src/utils/IdentifyTextUtils";

import *  as converter from '@aws-sdk/util-base64-browser';


import { blobToArrayBuffer } from "src/utils/Utils";
import { executeCall } from "./aws_apigateway_helpert";
import { sampleDocumentOneData } from "src/common/sampleDocumentData/sampleDocumentOne";
import { sampleDocumentTwoData } from "src/common/sampleDocumentData/sampleDocumentTwo";
import { sampleDocumentThreeData } from "src/common/sampleDocumentData/sampleDocumentThree";
import { sampleDocumentFourData } from "src/common/sampleDocumentData/sampleDocumentFour";

const DEMO_RESPONSES: { [key: string]: any } = {
    "sampleDocumentOne.webp": sampleDocumentOneData,
    "sampleDocumentOne.pdf": sampleDocumentOneData,
    "sampleDocumentOne.jpg": sampleDocumentOneData,
    "sampleDocumentTwo.pdf": sampleDocumentTwoData,
    "sampleDocumentThree.pdf": sampleDocumentThreeData,
    "sampleDocumentFour.pdf": sampleDocumentFourData,
}

async function executeAnalyzeDocumentAPI(file: any, isDemo: boolean, fileName: string): Promise<any> {
    if (isDemo) {
        return DEMO_RESPONSES[fileName]
    }
    const buffer = await blobToArrayBuffer(file);
    const fileBytes = new Uint8Array(buffer);
    const document = converter.toBase64(fileBytes)

    return await executeCall("/analyzedocument", {
        document
    });
}

export const analyzeDocument = async (file: any, isDemo: boolean, fileName?: string): Promise<any> => {
    try {
        const response = await executeAnalyzeDocumentAPI(file, isDemo, fileName || "");
        const Blocks = response.body.Blocks;
        if (!Blocks) {
            throw new Error("Invalid response from analyze document API");
        }
        return {
            textractData: categorizeTextractBlocks((Blocks as BlockList)),
            ...response.body.usage
        };
    } catch (err) {
        console.error("Error while analyzing the document: ", err);
        throw err;
    }
}
