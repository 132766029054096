import { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import { Document, Page, pdfjs } from 'react-pdf'
import { Paginator } from "./Paginator";
import { fetchDocument, getOverlayStyle, pdfPageToImage } from "./document_helpers";
import RenderTable from "./RenderTablet";
import { analyzeDocument } from "src/helpers/aws/aws_amplify_predictions";
import { downloadCSV, downloadExcel, extractFilename } from "src/utils/Utils";
import { NotEnougCreditsError, SubscriptionRequiredError } from "src/common/ApiResponseErrors";
import Paywall from "../Subscriptions/Paywall";
import { verifyUsage } from "src/helpers/usage/usage_helper";
import { UserDocument } from "src/helpers/aws/aws_s3_helper";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
interface Dimensions {
    width: number;
    height: number;
}

export interface DocumentAnalysisProps {
    documentId: string;
    isDemo: boolean;
    fileType: string;
}

const RenderDocumentAnalysis = (props: DocumentAnalysisProps) => {
    const [loading, setLoading] = useState(true);

    const documentId = props.documentId;
    let fileType = props.fileType;
    let isDemo: boolean = props.isDemo;

    const [userDocument, setUserDocument] = useState<UserDocument | null>(null);
    const [textractData, setTextractData] = useState<any>(null);
    const [overlays, setOverlays] = useState<any>([]);

    const pageRef = useRef<any>(null);
    const cardRef = useRef<HTMLDivElement>(null);
    const documentRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const [pageCount, setPageCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState(1);

    const [activeTable, setActiveTable] = useState(null);
    const [activeTableIndex, setActiveTableIndex] = useState(0);
    const [tableCount, setTableCount] = useState<number>(0);

    const [zoom, setZoom] = useState(1);
    const [pageDimensions, setDimensions] = useState<Dimensions>();

    const [isSubscriptionStatusChecked, setIsSubscriptionStatusChecked] = useState<boolean>(false);
    const [isSubscribed, setIsSubscribed] = useState<boolean>(true);

    const [creditsRemaining, setCreditsRemaining] = useState<number>(0);

    useEffect(() => {
        if (!documentId) return;
        if (isDemo) {
            setTimeout(() => {
                setUserDocument({
                    src: `/sampleDocuments/${documentId}`,
                    key: `${documentId}`,
                    lastModified: new Date()
                });
            }, 1000);
        } else {
            fetchDocument(documentId, setUserDocument);
        }
    }, [documentId]);

    const onPageChange = (newPageNumber: number) => {
        setPageNumber(newPageNumber);
        setOverlays([]);
        setZoom(1);
        setTableCount(0);
        setActiveTable(null);
        setActiveTableIndex(0);
        setTextractData(null);
    }

    const zoomIn = () => {
        setZoom(zoom + 0.1);
    }

    const zoomOut = () => {
        setZoom(zoom - 0.1);
    }

    useEffect(() => {
        if (isDemo) return;
        setLoading(true);
        verifyUsage().then((response) => {
            setLoading(false);
            setIsSubscribed(true);
            setIsSubscriptionStatusChecked(true);
            setCreditsRemaining(response.body.usage.creditsRemaining + 1);
        }).catch(error => {
            if (error instanceof SubscriptionRequiredError) {
                setIsSubscribed(false);
            } else if (error instanceof NotEnougCreditsError) {
                setIsSubscribed(true);
            }
            console.error(error);
            setIsSubscribed(false);
            setLoading(false);
            setIsSubscriptionStatusChecked(true);
        });
    }, []);

    useEffect(() => {
        if (!textractData || !textractData.text || !textractData.text.tables) return;
        setActiveTable(textractData.text.tables[activeTableIndex])
    }, [activeTableIndex])

    useEffect(() => {
        if (!textractData) return;
        if (textractData.text && textractData.text.tables) {
            setActiveTableIndex(0);
            setActiveTable(textractData.text.tables[0]);
            setTableCount(textractData.text.tables.length);
            const boundingBoxes = textractData.text.tables.map((table: any) => table.boundingBox);
            setOverlays(boundingBoxes);
        }
    }, [textractData])

    function handleBoundingBoxClick(tableIndex: number) {
        setActiveTableIndex(tableIndex);
        tableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
        setPageCount(numPages);
    }, []);


    async function analyzeUserDocument(imageData: any) {
        setLoading(true);

        const response = await fetch(imageData.url, {
            headers: {
                'Cache-Control': 'no-cache'
            }
        });

        if (!response.ok) {
            setLoading(false);
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();

        const file = new File([blob], 'page-image.png', { type: 'image/png' });

        // Analyze the image using the provided function
        analyzeDocument(file, isDemo, documentId).then(response => {
            // Store the response in the state
            setTextractData(response.textractData);
            if (response.usage) {
                setCreditsRemaining(parseInt(response.usage.creditsRemaining));
            }
            setLoading(false);
        }).catch(error => {
            if (error instanceof SubscriptionRequiredError) {
                setIsSubscribed(false);
            } else if (error instanceof NotEnougCreditsError) {
                setIsSubscribed(true);
            }
            console.error(error);
            setLoading(false);
        });
    }

    function RenderDocument() {
        if (!userDocument) return;

        if (fileType === 'pdf') {
            return RenderPdfDocument();
        } else if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png') {
            return RenderImageDocument();
        }
        return null;
    }

    function RenderDocumentHeader() {
        if (userDocument && fileType === "pdf") {
            return RenderPdfDocumentHeader();
        }
    }

    function RenderPdfDocumentHeader() {
        return (
            <>
                <h6 className="muted">Viewing: {extractFilename(documentId, true)} </h6>
                <p>Page {pageNumber} of {pageCount}</p>
                <Row>
                    <Col xl={10}>
                        <Paginator
                            previousLabelText="Previous Page"
                            nextLabelText="Next Page"
                            pageNumber={pageNumber}
                            pageCount={pageCount}
                            onPageChange={onPageChange}
                            isZeroBased={false} />
                    </Col>
                    <Col xl={2}>
                        <div className="float-right d-flex gap-3">
                            <a className="text-secondary hover-pointer" onClick={zoomOut}>
                                <i className="mdi mdi-magnify-minus font-size-22" id="deletetooltip" />
                            </a>
                            <a className="text-secondary hover-pointer" onClick={zoomIn}>
                                <i className="mdi mdi-magnify-plus font-size-22" id="edittooltip" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    function RenderImageDocument() {
        return (
            <div>
                <img
                    src={userDocument?.src}
                    alt="Uploaded Document"
                    ref={pageRef}
                    onLoad={async () => {
                        // Call the function to analyze the image document
                        await analyzeUserDocument({
                            url: userDocument?.src
                        });
                    }}
                    style={{ width: '100%', height: 'auto', position: 'relative' }}
                />
                {overlays.map((box: any, index: any) => (
                    <div key={index}
                        style={getOverlayStyle(box,
                            pageRef.current?.clientWidth || 1,
                            pageRef.current?.clientHeight || 1,
                            pageDimensions?.width || 1,
                            pageDimensions?.height || 1,
                            index === activeTableIndex, zoom)}
                        onClick={() => handleBoundingBoxClick(index)}>
                    </div>
                ))}
            </div>
        );
    }

    function RenderPdfDocument() {
        return <Document
            className={"pdf-container"}
            inputRef={documentRef}
            loading={<Spinner />}
            file={userDocument?.src}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.error('PDF load error:', error.message)}
            error={<span>Unable to load PDF</span>}
        >
            <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={window.innerWidth < 500 ? window.innerWidth : 500}
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                inputRef={pageRef}
                scale={zoom}
                className={"pdf-page"}
                onRenderSuccess={async (page) => {

                    // Convert the rendered page to an image
                    const imageData = await pdfPageToImage(page, zoom);
                    setDimensions({
                        width: imageData.width,
                        height: imageData.height,
                    });
                    // Convert data URL to a Blob, which can be used as a File
                    await analyzeUserDocument(imageData);
                }}
            >
                {overlays.map((box: any, index: any) => (
                    <div key={index}
                        style={getOverlayStyle(box,
                            pageRef.current?.clientWidth || 1,
                            pageRef.current?.clientHeight || 1,
                            pageDimensions?.width || 1,
                            pageDimensions?.height || 1,
                            index === activeTableIndex, zoom)}
                        onClick={() => handleBoundingBoxClick(index)}>
                    </div>
                ))}
            </Page>
        </Document>;
    }

    let creditsBadgeClassName = creditsRemaining <= 20 ?
        "bg-danger-subtle text-danger" :
        "bg-success-subtle text-success";

    let tableName = `${extractFilename(documentId, false)}_table_${activeTableIndex + 1}`;

    return (
        <>
            {!isDemo && <Breadcrumbs title="Bank Statement Converter AI" breadcrumbItem="Analyze Document" />}
            {!isDemo &&
                <h4 className={`badge ${creditsBadgeClassName}`}
                >
                    <b>{creditsRemaining}</b> credits remaining
                </h4>
            }
            {!isSubscribed && <Paywall isSubscribed={isSubscribed} embedded={true} />}
            {(isDemo || isSubscriptionStatusChecked && isSubscribed) &&
                <Row>
                    <Col xl={6}>
                        <Card innerRef={cardRef}>
                            <CardHeader>
                                {RenderDocumentHeader()}
                            </CardHeader>
                            {userDocument && (
                                <CardBody innerRef={cardRef}>
                                    <div>
                                        {RenderDocument()}
                                    </div>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                    <Col xl={6}>
                        <Card className="border-none">
                            <CardHeader>
                                {
                                    !loading && activeTable &&
                                    <div>
                                        <Row>
                                            <Col xl={6}>
                                                <div className="float-right d-flex gap-3">
                                                    <a className="text-secondary hover-pointer" onClick={() =>
                                                        downloadExcel(activeTable, tableName)}>
                                                        <span className="text-black">Download to Excel</span> <i className="mdi mdi-download font-size-22" id="deletetooltip" />
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="float-right d-flex gap-3">
                                                    <a className="text-secondary hover-pointer" onClick={() =>
                                                        downloadCSV(activeTable, tableName)}>
                                                        <span className="text-black">Download to CSV</span>
                                                        <i className="mdi mdi-download font-size-22" id="deletetooltip" />
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </CardHeader>
                            <CardBody className="dark overflow-x-auto">
                                {loading ? (
                                    <div className="text-center mt-3">
                                        <Spinner color="primary" />
                                        <div>Analyzing document...</div>
                                    </div>
                                ) : (
                                    activeTable ? (
                                        <div ref={tableRef}>
                                            <RenderTable tableData={activeTable} />
                                        </div>
                                    ) : <p>No data table detected</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
}
export default RenderDocumentAnalysis;

