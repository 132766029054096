import React, { useState } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { Card, CardBody, Form, Spinner, Alert } from "reactstrap";
import { uploadDocument } from "src/helpers/aws/aws_s3_helper";
import { convertToPDF, extractFilename } from "src/utils/Utils";

const DocumentUploader: React.FC<{ onUpload: (documentId: string) => void }> = ({ onUpload }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadError, setIsUploadError] = useState(false);

    const acceptedFileTypes: Accept = {
        images: ['image/jpeg', 'image/jpg', 'image/png'],
        documents: ['application/pdf'],
    };

    async function handleFileUpload(file: File) {
        setIsUploading(true);
        if (file) {
            let pdfFile = file;
            let documentFileName = file.name;
            try {
                pdfFile = await convertToPDF(file);
                documentFileName = `${extractFilename(file.name, false)}.pdf`;
            } catch (error) {
                console.log(error);
            }
            try {
                const response = await uploadDocument(pdfFile, documentFileName);
                setIsUploadError(true);
                onUpload(response.key); // Call onUpload prop function to refresh the document list
            } catch (error) {
                console.log(error);
                setIsUploadError(true);
            } finally {
            }
        }
        setIsUploading(false);
    }


    return (
        <React.Fragment>
            <Card className="card-h-100">
                <CardBody>
                    <div className="d-flex flex-wrap align-items-center mb-4 p-0">
                        <h5 className="card-title me-2">Upload Document</h5>
                    </div>
                    {
                        isUploadError &&
                        <Alert color="danger">
                            Failed to upload the document. Unsupported document format.
                            Please upload a PDF, JPEG, PNG, or JPG.
                        </Alert>
                    }
                    <Form className="hover-pointer">
                        {isUploading && (  // Conditionally render spinner and loading text
                            <div className="text-center mt-3">
                                <Spinner color="primary" />
                                <div>Uploading...</div>
                            </div>
                        )}
                        {!isUploading &&
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    setIsUploading(true);
                                    // Destructure the acceptedFiles array to get the first file
                                    const [file] = acceptedFiles;
                                    handleFileUpload(file);
                                }}
                                onDropAccepted={(_) => {
                                    setIsUploadError(false);
                                }}
                                onDropRejected={(_) => {
                                    setIsUploadError(true);
                                    setIsUploading(false);
                                }}
                                maxSize={5 * 1024 * 1024}  // maxSize should be in bytes
                                maxFiles={1}
                                accept={acceptedFileTypes}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>

                                        </div>
                                        <p className="m-3">Documents must be fewer than 11 pages, smaller than 5 MB, and one of the
                                            following formats: JPEG, PNG, or PDF.
                                        </p>
                                    </div>
                                )}
                            </Dropzone>
                        }
                    </Form>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default DocumentUploader;

