import { API, Auth } from "aws-amplify";
import { FreeTrialOverError, NotEnougCreditsError, SubscriptionRequiredError } from "src/common/ApiResponseErrors";

const API_NAME = "bankstatementconverai";

export const executeCall = async function (path: string, payload: any): Promise<any> {
    let userId = "";
    const currentUser = await Auth.currentUserInfo();
    if (currentUser && currentUser.attributes) {
        userId = currentUser.attributes.sub;
    }

    const myInit = {
        body: {
            ...payload,
            userId,
            IdentityToken: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
        }, // replace this with attributes you need
    };

    return await API.post(API_NAME, path, myInit)
        .catch((error) => {
            console.error(error);
            let hasResponse = error && error.response;
            if (hasResponse && error.response.status === 402) {
                throw new SubscriptionRequiredError("Subscription required");
            }
            else if (hasResponse && error.respose.status === 412) {
                throw new FreeTrialOverError("Free trial required");
            }
            else if (hasResponse && error.response.status === 429) {
                throw new NotEnougCreditsError("Not enough credits to complete the request");
            }
            else {
                throw error;
            }
        });
}
