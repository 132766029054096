/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "bankstatementconverai",
            "endpoint": "https://vg9udsfik2.execute-api.us-east-1.amazonaws.com/mainline",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:c69ce1e3-5ab5-4a16-a8cb-a10ba9402ece",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Dhg7kl4Uj",
    "aws_user_pools_web_client_id": "243dbkl0ig3j05rvaqpfpoq42t",
    "oauth": {
        "domain": "bankstatementconvert86d8e4c5-86d8e4c5-mainline.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://bankstatementconverterai.com/",
        "redirectSignOut": "https://bankstatementconverterai.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bankstatementconvert32097e5595c647a0813f10468ed221620-mainline",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "bankstatementconverterdynamof8ad2c5f-mainline",
            "region": "us-east-1"
        }
    ],
    "predictions": {
        "identify": {
            "identifyText": {
                "proxy": false,
                "region": "us-east-1",
                "defaults": {
                    "format": "TABLE"
                }
            }
        }
    }
};


export default awsmobile;
