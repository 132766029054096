import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CreativeProjectMonth from "./CreativeProjectMonth";
import { checkUserAuthentication } from "src/utils/Utils";
import Paywall from "src/pages/Subscriptions/Paywall";

const monthData = [
  {
    id: 1,
    title: "Basic",
    price: "15",
    desc: "Allows you to convert 250 pages per month to data tables. Includes 10 bonus pages for free.",
  },
  {
    id: 2,
    title: "Standard",
    price: "30",
    isFeatured: true,
    desc: "Allows you to convert 500 pages per month to data tables. Includes 50 bonus pages for free.",
  },
  {
    id: 3,
    title: "Premium",
    price: "60",
    desc: "Allows you to convert 1000 pages per month to data tables. Includes 100 bonus pages for free.",
  },
];

const PagePricing = () => {
  const [activeTab, toggleTab] = useState("1");

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    checkUserAuthentication().then((isAuthentcated) => {
      setIsAuthenticated(isAuthentcated);
    });
  }, [])

  return (
    <React.Fragment>
      {isAuthenticated &&
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="BankStatementConverterAI" breadcrumbItem="Pricing" />
            <Paywall isSubscribed={false} embedded={true} />
          </Container>
        </div>}
      {!isAuthenticated && <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">
                Select the plan that fits your budget and start converting all your PDFs and
                Images to Excel data format.
              </h4>
              <div className="flex-shrink-0 align-self-end">
                <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames(
                        {
                          active: activeTab === "1",
                        },
                        "px-3 rounded monthly"
                      )}
                      onClick={() => {
                        toggleTab("1");
                      }}
                    >
                      Monthly
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </CardHeader>
            <div className="card-body">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="row d-flex justify-content-center" >
                    <CreativeProjectMonth monthData={monthData} />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Card>
        </Col>
      </Row>
      }
    </React.Fragment>
  );
};

export default PagePricing;
